import { NpsEnum } from "../../enum/nps.enum";

export const props = () => ({
  zendeskId: "cab743de-0681-4851-9630-98da2a6e3d80",
  apiUrl: "https://api-cimoagro-prod.graodireto.com.br",
  logo: "graodireto",
  appTitle: "Barter Fácil",
  theme: "graodireto",
  companyLanguage: "default",
  keyCloakConfig: {
    url: "https://auth.graodireto.com.br",
    realm: "cimoagro",
    clientId: "cimoagro-front",
  },
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyClggNi_Cl9meYCXocmYx0kVJPI20SLLT0",
    authDomain: "barter-cimoagro.firebaseapp.com",
    projectId: "barter-cimoagro",
    storageBucket: "barter-cimoagro.appspot.com",
    messagingSenderId: "113811071479",
    appId: "1:113811071479:web:dda229f2785da7d51c0ef2",
    measurementId: "G-H90R7HFHC3",
  },
  hotjarConfig: "4946542",
  nps: {
    api: "https://api.graodireto.com.br/api",
    url: "https://graodireto.com.br/nps",
    key: "109d2ffc-e06b-4c6a-88c2-6a1b9095dc75",
  },
  npsSourceId: NpsEnum.EASY_BARTER_CIMOAGRO,
});
